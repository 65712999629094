import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Assay } from '../interfaces/assay.interface';
import { Comment, CommentsComponent, KeyboardService, Lab } from '@lims-common-ux/lux';
import { AssayService } from './assay.service';
import { of } from 'rxjs';
import { AppStateService } from '../app-state.service';
import { ResultType } from '../interfaces/result.interface';

@Component({
  selector: 'app-assay',
  templateUrl: './assay.component.html',
  styleUrls: ['./assay.component.scss'],
})
export class AssayComponent implements OnInit {
  @Input()
  assay: Assay;

  @Input()
  excluded = false;

  @Input()
  isSelected = false;

  @Output()
  exclude = new EventEmitter<Assay>();

  @ViewChild('assayName')
  assayName: ElementRef;

  @ViewChild('assayWrapper')
  assayWrapper: ElementRef;

  @ViewChild('assayComments')
  assayComments: CommentsComponent;

  lab: Lab;
  originatingLab: string;

  constructor(
    private assayService: AssayService,
    private keyboardService: KeyboardService,
    public appStateService: AppStateService
  ) {}

  ngOnInit() {
    this.lab = this.appStateService.lab;
    this.originatingLab = this.appStateService.accession.originatingLab;
  }

  onAddComment(comment: Comment) {
    // Prevent calls to backend when a comment already exists on an assay
    const duplicateComments =
      comment && comment.id && this.assay.comments.filter((assayComment) => comment.id === assayComment.id);

    if (!this.appStateService.loading && duplicateComments && duplicateComments.length < 1) {
      this.appStateService.loading = true;

      this.assayService.addComment(this.assay, comment.id).subscribe({
        next: () => {
          this.appStateService.loading = false;
        },
        error: (error) => {
          this.selectAssay();
        },
      });
    }
    this.assayComments.resetComments();
  }

  onRemoveComment(comment: Comment) {
    if (!this.appStateService.loading) {
      this.appStateService.loading = true;

      this.assayService.deleteComment(this.assay, comment.id).subscribe({
        next: (r) => {
          this.appStateService.loading = false;
        },
        error: (error) => {
          this.selectAssay();
        },
      });
    }
  }

  showCommentsModule() {
    return (
      (this.assay.comments && this.assay.comments.length > 0) ||
      (this.assay._links && this.assay._links.addComment && this.assay._links.addComment.href)
    );
  }

  showCommentsSearchInput() {
    return this.assay._links && this.assay._links.addComment && this.assay._links.addComment.href && this.isSelected;
  }

  handleExcludeCheckbox($event) {
    this.excluded = !this.excluded;
    this.exclude.emit(this.assay);
  }

  handleMousedown($event) {
    $event.preventDefault();
    $event.stopImmediatePropagation();

    if (!this.isSelected) {
      this.selectAssay();
    }
  }

  createRepeatKeyboardAction() {
    return {
      name: 'repeat-assay',
      matchCallback: ($evt) => {
        if (this.assay?._links?.requestRepeat) {
          this.repeat($evt);
        } else {
          $evt.preventDefault();
          $evt.stopImmediatePropagation();
        }
      },
    };
  }

  createNoResultKeyboardAction() {
    return {
      name: 'no-result-assay',
      matchCallback: ($evt) => {
        if (this.assay?._links?.markAsNoResult) {
          this.noResult($evt);
        } else {
          $evt.preventDefault();
          $evt.stopImmediatePropagation();
        }
      },
    };
  }

  handleFocusIn() {
    // add keyboard actions specific to this assay
    this.keyboardService.addActions([this.createNoResultKeyboardAction(), this.createRepeatKeyboardAction()]);

    if (!this.isSelected) {
      setTimeout(() => {
        if (this.assayWrapper.nativeElement.contains(document.activeElement)) {
          this.selectAssay();
        }
      }, 0);
    }
  }

  selectAssay(focus = true) {
    this.isSelected = true;

    setTimeout(() => {
      this.appStateService.currentAssay = of(this.assay);

      // after setting the assay as selected
      // the comments field will be rendered
      // which gets focused by default
      if (focus) {
        if (this?.assayComments?.allowAdd) {
          this.assayComments.focusSearchInput();
        } else {
          this.assayName?.nativeElement.focus();
        }
      }
    }, 0);
  }

  handleFocusOut() {
    // remove keyboard actions specific to this assay
    this.keyboardService.removeAction(this.createNoResultKeyboardAction());
    this.keyboardService.removeAction(this.createRepeatKeyboardAction());

    if (this.isSelected) {
      setTimeout(() => {
        if (!this.assayWrapper.nativeElement.contains(document.activeElement)) {
          this.isSelected = false;
        }
      }, 0);
    }
  }

  handleCommentSelection($event) {
    if (this.assayComments && this.isSelected) {
      $event.preventDefault();
      $event.stopImmediatePropagation();

      if (this?.assayComments?.allowAdd) {
        this.assayComments.focusSearchInput();
      }
    }
  }

  noResult($event) {
    $event.preventDefault();
    $event.stopImmediatePropagation();

    this.appStateService.loading = true;

    this.assayService.noResult(this.assay).subscribe({
      next: (r) => {
        this.appStateService.loading = false;
        this.appStateService.currentAssay = of(this.assay);
      },
      error: (err) => {
        throw err;
      },
    });
  }

  repeat($event) {
    $event.preventDefault();
    $event.stopImmediatePropagation();

    this.appStateService.loading = true;

    this.assayService.repeat(this.assay).subscribe({
      next: (r) => {
        this.appStateService.loading = false;
        this.appStateService.currentAssay = of(this.assay);
      },
      error: (err) => {
        throw err;
      },
    });
  }

  /* Line required by template reference */
  protected readonly ResultType = ResultType;
}
