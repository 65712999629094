import { Injectable } from '@angular/core';
import { Assay } from '../interfaces/assay.interface';
import { HttpClient } from '@angular/common/http';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { AccessionService } from '../accession/accession.service';
import { AppStateService } from '../app-state.service';
import { GlobalErrorHandlerService, populateLink } from '@lims-common-ux/lux';
import { of } from 'rxjs';
import { Accession } from '../interfaces/accession';

@Injectable({
  providedIn: 'root',
})
export class AssayService {
  constructor(
    private http: HttpClient,
    private appStateService: AppStateService,
    private globalErrorHandlerService: GlobalErrorHandlerService,
    private accessionService: AccessionService
  ) {}

  addComment(assay: Assay, commentId: string) {
    const url = populateLink(assay._links.addComment, { commentId });

    return this.http
      .put<Assay>(url, null, { headers: { 'If-Match': this.appStateService.accession.version }, observe: 'response' })
      .pipe(
        catchError((err) => {
          this.globalErrorHandlerService.handleError(err);
          return of(err);
        }),
        tap((response: any) => {
          this.appStateService.loading = true;
          // Get updated accession aggregate
          this.setAccession(response);
        })
      );
  }

  deleteComment(assay: Assay, commentId: string) {
    const comment = assay.comments.find((cmt) => cmt.id === commentId);
    const url = comment?._links?.removeComment?.href;

    return this.http
      .delete(url, { headers: { 'If-Match': this.appStateService.accession.version }, observe: 'response' })
      .pipe(
        catchError((err) => {
          this.globalErrorHandlerService.handleError(err);
          return of(err);
        }),
        switchMap((response: any) => {
          return this.accessionService.loadAccession(this.appStateService.accession.accessionId);
        })
      );
  }

  noResult(assay: Assay) {
    const href = assay._links.markAsNoResult.href;
    return this.http
      .put<Accession>(href, null, {
        headers: { 'If-Match': this.appStateService.accession.version },
        observe: 'response',
      })
      .pipe(
        tap((response) => {
          this.setAccession(response);
        })
      );
  }

  repeat(assay: Assay) {
    const href = assay._links.requestRepeat.href;
    return this.http
      .put<Accession>(href, null, {
        headers: { 'If-Match': this.appStateService.accession.version },
        observe: 'response',
      })
      .pipe(
        tap((response) => {
          this.setAccession(response);
        })
      );
  }

  private setAccession(accessionResponse) {
    window.requestAnimationFrame(() => {
      this.accessionService.setAccessionFromResponse(accessionResponse);
    });
  }
}
